import './App.css';
import {  
  Link,
  Switch,
  Route } from "react-router-dom";
import Home from './components/pages/Home';
import Tutorials from './components/pages/Tutorials';
import NavBar from '../src/components/NavBar';
import Projects from './components/pages/Projects';
import Contact from './components/pages/Contact';


function App() {
  return (
    <div className="App">
      <NavBar />
      <div className="App-body">
        <Switch>
         <Route path='/' exact >
            <Home/>
         </Route><Route path='/projects' exact >
            <Projects/>
         </Route>
         <Route path='/tutorials' exact >
            <Tutorials/>
         </Route>
         <Route path='/contact' exact >
            <Contact/>
         </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
