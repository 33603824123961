import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './Projects.css'
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";

function Projects() {

    const screenSize = useWindowSize();

    // Hook used to trigger state updates to the screensize
    function useWindowSize() {
    
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });
        useEffect(() => {
        // Handler to call on window resize
            function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            }
            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
             handleResize();
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
    }

    // Project Item List data object
    const ProjectsListData = [
        {
            title: 'App Security Compliance Application',
            text: '',
            video: '',
            date: 'April 2021 - September 2021',
            category: {
                techtag: 'Pega',
                techcolor: '#24038a',
                orgtag: 'Capgemini',
                orgcolor: '#1ea4da'
            },
            link: {
                url: '',
                text: ''
            },
            responsibilities: [
                {text: "Collaborated with other Pega developers on the project to resolve bugs in preparation to deploy the application to production."},
                {text: "Responsible for developing the deployment and data migration strategy used for the application."}
            ]
        },
        {
            title: 'Resource Need Forecasting Framework & Implementation Layer Apps',
            text: '',
            video: '',
            date: 'October 2020 - April 2021',
            category: {
                techtag: 'Pega',
                techcolor: '#24038a',
                orgtag: 'Capgemini',
                orgcolor: '#1ea4da'
            },
            link: {
                url: '',
                text: ''
            },
            responsibilities: [
                {text: "Flexibly addressed functionality requests from the client and offered solutions tailored to the client’s needs and expectations."},
                {text: "Acted as primary resource integrating design requests into overall application design to meet client needs while maintaining design consistency and following best practices."},
                {text: "Responsible for the project deployment and data migration strategy."},
                {text: "Collaborated with multiple teams to design and build a complex integration with an external system."}
            ]
        },
        {
            title: '.NET Application Support',
            text: '',
            video: '',
            date: 'September 2020 - October 2020',
            category: {
                techtag: '.NET',
                techcolor: '#6f42f5',
                orgtag: 'Capgemini',
                orgcolor: '#1ea4da'
            },
            link: {
                url: '',
                text: ''
            },
            responsibilities: [
                {text: "Worked with application support organization to troubleshoot issues reported on applications and developed code changes if needed."}
            ]
        },
        {
            title: 'Software Request App',
            text: '',
            video: '',
            date: 'July 2020 - December 2020',
            category: {
                techtag: 'Pega',
                techcolor: '#24038a',
                orgtag: 'Capgemini',
                orgcolor: '#1ea4da'
            },
            link: {
                url: '',
                text: ''
            },
            responsibilities: [
                {text: "Responsible for the Solution Architecture Design and implementation of the Pega portion of this system as the only Pega developer during my time on the project."},
                {text: "Collaborated with many developers working on other components of the system to build integrations that coordinated the end to end processing of requests."},
                {text: "Presented the team with multiple options for solutions that could be leveraged and pointed out strengths and weaknesses of each in order to come up with an overall system design that is both flexible and scalable."}
            ]
        },
        {
            title: 'Finance Funding Application',
            text: '',
            video: '',
            date: 'May 2020 - September 2020',
            category: {
                techtag: 'Pega',
                techcolor: '#24038a',
                orgtag: 'Capgemini',
                orgcolor: '#1ea4da'
            },
            link: {
                url: '',
                text: ''
            },
            responsibilities: [
                {text: "Responsible for designing and developing two complex components of the application."},
                {text: "Collaborated with other members of the project team to come up with solutions to design challenges."},
                {text: "Monitored the application during the hypercare portion of the project to quickly diagnose and develop solutions to production issues."}
            ]
        },
        {
            title: 'Application Sustainment & Enhancement Activities',
            text: '',
            video: '',
            date: 'Febuary 2020 - September 2021',
            category: {
                techtag: 'Pega',
                techcolor: '#24038a',
                orgtag: 'Capgemini',
                orgcolor: '#1ea4da'
            },
            link: {
                url: '',
                text: ''
            },
            responsibilities: [
                {text: "Acted as the primary contact for business stakeholders on 5 different applications to research/resolve any production bugs."},
                {text: "Acted as the primary contact to research and provide a level of effort for all proposed enhancement requests from business stakeholders for 5 different applications."},
                {text: "Worked with business stakeholders to capture requirements, design and develop enhancements to add value on a swift timeline alongside other project work."},
                {text: "Developed multiple solutions to resolve issues with inflight cases."}
            ]
        },
        {
            title: 'HR Remote Work & Work Schedule Change App',
            text: '',
            video: '',
            date: 'August 2019 - March 2020',
            category: {
                techtag: 'Pega',
                techcolor: '#24038a',
                orgtag: 'Capgemini',
                orgcolor: '#1ea4da'
            },
            link: {
                url: '',
                text: ''
            },
            responsibilities: [
                {text: "Responsible for the Solution Architecture Design and implementation as the sole developer on the project."},
                {text: "Acted as the primary contact for technical inquiries and provided accurate and timely support for all requests."},
                {text: "Designed and developed multiple integrations with external systems."},
                {text: "Troubleshooting to quickly diagnose and resolve production issues."}
            ]
        },
        {
            title: 'Bridgr App',
            text: 'Bridgr is a proof of concept iOS application that facilitates long distance carpooling. This application allows drivers to post destinations on an interactable map so that they can be linked with students that need a ride to a location within close proximity of the posted destination. The riders and driver are linked in a common chat board where they can discuss ride details among themselves. The goal of Bridgr is to allow drivers to utilize extra space in their car in turn for fellowship and/or gas money.',
            video: 'https://www.youtube.com/embed/qwQUGA35-bk',
            date: 'August 2018 - May 2019',
            category: {
                techtag: 'React Native',
                techcolor: '#424bf5',
                orgtag: 'UofSC',
                orgcolor: '#61110f'
            },
            link: {
                url: '',
                text: ''
            },
            responsibilities: [
                {text: "Collaborated with other developers on the project to meet dates set for project milestones."},
                {text: "Responsible for building out many key features in the application including message board, google places search, and google maps integration."}
            ]
        }
        ];

        // The below vars are used to calculate video height and width within project cards
        // Used in CalcProjVidWidth() & CalcProjVidHeight() functions

        // The offset used for between video witdth and the full screen size --> 3% of total screen width
        const vidWidthOffset = window.innerWidth * 0.3;

        // This is the screen witdth where we switch to calculating video height and width dynamically
        const vidSnapWidth = 750;

        function handleResize() {
            console.log("Resize Triggered")
            ProjectList()
        }

        function CalcProjVidWidth(widthParam) {

            const width = widthParam;

            // add the resize listener to the window 
            // window.addEventListener('resize', handleResize)

            if(width < vidSnapWidth) {
                return width - vidWidthOffset
            } else {
                return "560"
            }
        }

        function CalcProjVidHeight(widthParam) {

            const width = widthParam;

            if(width < vidSnapWidth) {
                return (width - vidWidthOffset) * (315/560)
            } else {
            return "315"
            }
        }
        
        
        function ProjItemViz(orgTagParam) {
            
            let orgTag = useLocation().search;
            let cleanOrgTag = orgTag.replace("?orgtag=","")
            if ((orgTagParam != cleanOrgTag) && (cleanOrgTag != "")) {
                return "none"
            } else {
                return "block"
            }
        }

        function SectionViz(Param) {
            if(Param != "") {
                return "block"
            } else {
                return "none"
            }
        }

        const ProjectItem = ({data, screenWidth}) => (
            <div className="project-item" style={{ display:  ProjItemViz(data.category.orgtag)}}>
                <div className="project-item-content">
                    <span className="techtag" style={{ background: data.category.techcolor }}>
                        {data.category.techtag}
                    </span>
                    <span className="orgtag" style={{ background: data.category.orgcolor }}>
                        {data.category.orgtag}
                    </span>
                    <time>{ data.date }</time>
                    <h2>{ data.title }</h2>
                    <div className="ProjVidDisplay" style={{ display:  SectionViz(data.video)}}>
                        <iframe className="ProjVid" width={ CalcProjVidWidth(screenWidth) } height={ CalcProjVidHeight(screenWidth) } src={ data.video } title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
                    </div>
                    <div className="ProjDescDisplay" style={{display: SectionViz(data.text)}}>
                        <p>{ data.text }</p>
                    </div>
                    <div className="ProjRespListDisplay">
                        <div className="RespHeader">
                            <h3>Responsibilities: </h3>
                        </div>
                        <div className="RespList">
                          <ul>
                            {
                                data.responsibilities.map((data, idx) => (
                                <li key={idx}>{data.text}</li>
                                ))
                            }
                          </ul>
                        </div>
                    </div>
                </div>
            </div>
        );

    const ProjectList = (props) => 
        ProjectsListData.length > 0 && (
            <div className="projects-container-inner">
                {ProjectsListData.map((data, idx) => (
                    <ProjectItem data={data} key={idx} screenWidth={props.screenWidth} />
                ))}
            </div>
        );


    return (
        <>
            <div className='projects-container'>
                <div className='projects-header'>
                    <h1>Projects</h1>
                </div>
                <div className='projects-body'>
                    <ProjectList screenWidth={screenSize.width}/>
                </div>
            </div>
        </ >
    )
}

export default Projects