import React from 'react'
import { Button } from './Button'
import './HeroSection.css';
import '../App.css';

function HeroSection() {

    return (
        <div className='hero-container'>
            {/*<video src="/videos/video-2.mp4" autoPlay loop muted /> */}

            <h1>Ely Yonce</h1>
            <div className="hero-contnet-container">
                <div className="hero-cc-body">
                <div className="hero-cc-left">
                    <img className="headshot" src="/images/EYonce.jpg" alt="Ely Yonce" />
                </div>
                <div className="hero-cc-right">
                <h2>Software Developer with a passion for automation. </h2>
                    <h2>Pega Senior System Architect</h2>
                    <h2>Pega Decisioning Consultant</h2>
                </div>
                </div>
            </div>
            <div className="mobileMainLine">
                <h2>Software Developer with a passion for automation. </h2>
                    <h2>Pega Senior System Architect</h2>
                    <h2>Pega Decisioning Consultant</h2>
            </div>
        </div>
    )
}

export default HeroSection
