import React from 'react';
import ReactDOM from 'react-dom';
import Timeline from '../../Timeline';
import HeroSection from '../HeroSection';


class Home extends React.Component {

    render(){
        return (
            <>
                <HeroSection />
                <Timeline />
            </>
        );
    }
}

export default Home;