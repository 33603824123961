import React from 'react'
import './Timeline.css'
import { Link } from "react-router-dom";


function Timeline() {

    const WorkTimelineData = [
        {
            text: 'Software Developer - Pega Senior System Architect',
            date: 'Sept 2021 - Current',
            category: {
                tag: 'TISTA',
                color: '#0a20c9'
            }
        },
        {
            text: 'Software Developer - Pega Senior System Architect',
            date: 'June 2019 - Sept 2021',
            category: {
                tag: 'Capgemini',
                color: '#1ea4da'
            },
            link: {
                url:'/projects?orgtag=Capgemini',
                text: 'View Projects'
            }
        },
        {
            text: 'University of South Carolina - Bachelor of Science in Computer Science; Minor in Data Science; Magna Cum Laude; 3.8 Overall GPA',
            date: 'May, 2019',
            category: {
                tag: 'UofSC',
                color: '#61110f'
            },
            link: {
                url:
                    '/projects?orgtag=UofSC',
                text: 'View Projects'
            }
        }
        
    ];


    const TimelineItem = ({data}) => (
        <div className="timeline-item">
            <div className="timeline-item-content">
                <span className="tag" style={{ background: data.category.color }}>
                    {data.category.tag}
                </span>
                <time>{ data.date }</time>
                <p>{ data.text }</p>
                {data.link && (
                    <Link to={data.link.url} className='orgProjLinks'>
                        {data.link.text}
                    </Link>
                )}
                <span className="circle" />
            </div>
        </div>


    );

    const Timeline = () => 
                WorkTimelineData.length > 0 && (
                    <div className="timeline-container-inner">
                        {WorkTimelineData.map((data, idx) => (
                            <TimelineItem data={data} key={idx} />
                        ))}
                    </div>
                );


    return (
        <>
            <div className='timeline-container'>
                <div className='timline-header'>
                    <h1>Work Timeline</h1>
                </div>
                <div className='timeline-body'>
                    <Timeline />
                </div>
            </div>
        </>
    )
}

export default Timeline
