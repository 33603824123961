import React, {useState} from 'react'
import {  
    Link,
    Switch,
    Route } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons'
import { Button } from './Button';
import './NavBar.css';



function NavBar() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth ,+ 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };


    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    Ely Yonce
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <FontAwesomeIcon icon={click ? faTimes : faBars} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/projects' className='nav-links' onClick={closeMobileMenu}>
                            Projects
                        </Link>
                    </li>
                    {/*
                    <li className='nav-item'>
                        <Link to='/tutorials' className='nav-links' onClick={closeMobileMenu}>
                            Tutorials
                        </Link>
                    </li>
                    
                    <li className='nav-item'>
                        <a href="https://drive.google.com/uc?id=1W7mKMJffUNtKOX_VDGO1Z0ZqpxKCbmvM&export=download" className='nav-links'>
                            Resume
                        </a>
                    </li>
                    */}
                    <li className='nav-item'>
                        <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                            Contact
                        </Link>
                    </li>
                </ul>


            
                </div>
            </nav>
        </>
    )
}

export default NavBar
