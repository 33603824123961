import React from 'react';
import ReactDOM from 'react-dom';


function Contact() {
    return (
        <>
           <h1>Contact</h1>
           <p>Send me an email at <a href="mailto:elyyonce11@gmail.com">elyyonce11@gmail.com</a></p>
        </ >
    )
}

export default Contact
