import React from 'react';
import ReactDOM from 'react-dom';


function Tutorials() {
    return (
        <>
            <h1>This is the Tutorials</h1>
        </ >
    )
}

export default Tutorials
